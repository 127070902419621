<template>
    <div class="quemSomos">
        <div class="container-fluid px-0">
            <div class="row align-items-center justify-content-center">
                <div class="col-12 stripe text-center" title="Nome comum: Aranha-caranguejo; Crédito: Afonso Meneses">
                    <span class="tit1">Sobre o</span><br />
                    <span class="tit2">SALVE</span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row text-left pt-5" >
                <div class="col-md-6 col-12">
                    <p class="text-justify">
                        O Sistema de Avaliação do Risco de Extinção da Biodiversidade - SALVE foi desenvolvido com o objetivo de facilitar a gestão do processo de avaliação do risco de extinção coordenado e executado pelo ICMBio.
                    </p>
                    <p class="text-justify">
                        Por meio do SALVE, estabelecemos uma plataforma de dados destinada ao armazenamento e organização das informações pertinentes à avaliação das espécies e dos atores envolvidos no processo. Além disso, o SALVE desempenha um papel fundamental como instrumento de controle, monitoramento e execução das diversas etapas do processo, desde a compilação de dados até a análise do risco de extinção e subsequente divulgação dos resultados.
                    </p>
                </div>
                <div class="col-md-6 col-12">
                    <p class="text-justify">
                        O desenvolvimento do SALVE teve início em 2016 pela equipe interna do ICMBio. A discussão das funcionalidades necessárias contou com a participação de servidores e bolsistas vinculados aos Centros Nacionais de Pesquisa e Conservação e da sede do ICMBio, todos com atuação no Processo de Avaliação do Risco de Extinção da Fauna.
                    </p>
                    <p class="text-justify">
                        O SALVE é operado por servidores do ICMBio. Os parceiros, especialistas da comunidade científica, atuam incluindo e atualizando dados sobre as espécies da fauna no banco de dados e auxiliando o processo por meio da organização, revisão e correção dessas informações, além da realização em si da avaliação do risco de extinção das espécies nas oficinas e posterior validação dos resultados.
                    </p>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row text-left mt-5" >
                <div class="col align-self-center">

                    <div style="max-width: 100%">

                        <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
                            <video width="1100px" height="600px"  preload="none" controls>
                            <source src="video/apresentacao-salve.mp4" type="video/mp4" autostart="false">
                            Ops! Seu navegador não suporta este formato de vídeo.
                            </video>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>

import api from '@/services/api.js'
export default {
    data() {
        return {
            items: [],
            activeBtn:'btn1'
        }
    },
    created() {
        var t = this
        api.get('/redBooks').then(response => (
            t.items = response.data.data
        ))
    },
    methods: {
    }
}
</script>

<style scoped>
    .text-justify{
        text-align: justify;
    }
    .stripe{
        background-image: url(/img/animals/aranha-full.png);
        background-size: cover;
        height: 350px;
        padding-top: 10rem;
    }
    .ball-list {
        margin-top: -50px;
    }
    .row-text {
        margin-right: 12%;
        margin-left: 12%;
    }
    .table-button {
        background-color: transparent;
        margin-left: 25px;
        margin-right: 25px;
        border: none;
        border-bottom: 7px solid #ffffff;
    }
    .active {
        font-weight: bold;
        border-bottom: 7px solid #E2F5A1;
    }
    .separator {
        margin-bottom: 30px;
        border-bottom: 3px solid #E2F5A1;
        margin-left: 12vw;
        margin-right: 12vw;
    }
        .tit1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #282828;
        border-top: 3px solid black;
        padding-top: 1.5rem;
    }
    .tit2 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #282828;
    }
@media screen and (max-width: 600px) {
    .tit1 {
    font-size: 1.3rem;
    padding-top: .5rem;
    }
    .tit2 {
        font-size: 1.3rem;
    }
    .stripe{
        height: 115px;
        padding-top: 40px;
    }
}
</style>